import { useState, useEffect } from 'react'
import { Container, Row, Col, Button } from "react-bootstrap";


const Form81 = ({userData,changeShippingAddress,setShowModal,setShowModal3 })=> {
  // useEffect(()=>{
  //   console.log(name)
  //   console.log(input)
  //   console.log(index)  
  // },[])
  // const [red, setRed] = useState(false)
  const [choosen, setChoosen] = useState({})

  useEffect(()=>{
    console.log(choosen)
    console.log(setShowModal3)
  },[choosen])
  
  useEffect(()=>{
    
  })


  return (
    <div>
      <div >
        {

        userData['shippingAddresses'].map((item,index)=>{
          console.log(item)
          return(
              <div style={{textAlign:'left'}}>
              <input style={{textAlign:'left'}} type='radio' onChange={()=>setChoosen(item)} value={item} name={'shippingAddresses'}></input>
              <span style={{paddingLeft:5}}>
              {
                item['address2']!=''?
                item['name']+', '+item['address']+', '+item['address2']+', '+item['city']+', '+item['state']+', '+item['zip']+', '+item['country']
                :item['name']+', '+item['address']+', '+item['city']+', '+item['state']+', '+item['zip']+', '+item['country']
              }
              </span>
              </div>
            )
        })
        
        }
      </div>
      <span style={{paddingTop:10,paddingLeft:10,float:'left'}}> 
        <Button onClick={()=>setShowModal(true)}>Add New</Button>
      </span>
      <span style={{paddingTop:10,paddingLeft:10,float:'left'}}> 
        <Button onClick={()=>{
          if(Object.keys(choosen).length>0){
            changeShippingAddress(choosen)
          }
          setShowModal3(true)
        }}>Edit</Button>
      </span>
      
      <span style={{paddingTop:10,paddingRight:10,float:'right'}}> 
        <Button onClick={()=>changeShippingAddress(choosen)} >Change</Button>
      </span>

    </div>
  );
}

export default Form81