import {initializeApp} from 'firebase/app';


// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyACKWQN-BNiZDZgM9wHoGQyin0O6RO9wGo",
  authDomain: "consultajent.firebaseapp.com",
  projectId: "consultajent",
  storageBucket: "consultajent.appspot.com",
  messagingSenderId: "364016585304",
  appId: "1:364016585304:web:44c4a5b5849b2f39e6982d",
  measurementId: "G-QVKM5R6J41"
};

const app = initializeApp(firebaseConfig);

// if (!firebase.apps.length) {
// 	initializeApp(firebaseConfig);
// }else {
//    firebase.app(); // if already initialized, use that one
// }

export default app