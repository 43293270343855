import React, { useState, useEffect,useRef } from "react";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";

// import CheckoutForm from "./CheckoutForm.js";

import '../App.css';
import Form8 from './Form8.js';
import Form81 from './Form81.js';
import Form82 from './Form82.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDoc } from "firebase/firestore";
import { getFirestore, setDoc, collection, query, where, doc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from './firebase.js';
import LoginModal from './LoginModal.js'
import { FaCaretDown,FaCaretUp,FaSearch,FaRedo,FaBars } from 'react-icons/fa';
import {Modal, Spinner} from 'react-bootstrap';
import Dropdown from 'react-dropdown';
import { getStorage,ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom"
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

function Checkout({user,db,auth,setShowLogin,userData,cartObj,setCartObj}) {
  
  const storage = getStorage(firebase);
  const navigate = useNavigate()
  const functions = getFunctions(firebase)
  const createStripePayment = httpsCallable(functions, 'createStripePayment');
  const [openDrop,setOpenDrop] = useState(false);

  const [search, setSearch] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showModal1, setShowModal1] = useState(false)
  const [showModal2, setShowModal2] = useState(false)
  const [showModal3, setShowModal3] = useState(false)
  const [showModal4, setShowModal4] = useState(false)
  const [response, setResponse] = useState('Placing Order...')
  const [showChangeCard, setShowChangeCard] = useState(false)
  const [imageRef,setImageRef] = useState({})
  const [cartRef,setCartRef] = useState({total:0,itemNum:0})
  const [newAddressObj,setNewAddressObj] = useState({'name':'','address':'','address2':'','city':'','state':'','zip':'','country':''})
  const [cvc,setCvc] = useState('')
  const [expiry,setExpiry] = useState('')
  const [focus,setFocus] = useState('')
  const [name,setName] = useState('')
  const [number,setNumber] = useState('')
  const [cardType,setCardType] = useState('')
  const [isValid,setIsValid] = useState(false)
  const [paymentMethods,setPaymentMethods] = useState({})
  const [paymentMethod,setPaymentMethod] = useState('')
  const [customer,setCustomer] = useState({})

  useEffect(()=>{
    if(user!=undefined&&user.uid!=undefined){
      // check()
    }else{
      console.log('user',user)
      setShowLogin(true)

    }
  },[user]) 
  useEffect(()=>{
    if(userData!=undefined&&userData['shippingAddresses']==undefined){
      // check()
      userData['shippingAddresses']=[]
    }
  },[userData])

  useEffect(()=>{
    console.log(cartObj)
    if(Object.keys(cartObj).length>=1){
          console.log('yup1')
      setCartRef({total:0,itemNum:0})
      setImageRef({})
      Object.keys(cartObj).forEach((item,index)=>{
          console.log(item)
        if(cartObj[item]['prod'][0]['photos']!=undefined&&cartObj[item]['prod'][0]['photos'].length>0){

          const downRef = ref(storage,'/photos/'+cartObj[item]['prod'][0]['photos'][0].storeId)
          getDownloadURL(downRef).then((url) => {
            console.log('yup2')
            // Insert url into an <img> tag to "download"
            setImageRef((prev)=>{
              const clone = JSON.parse(JSON.stringify(prev))

              clone[cartObj[item]['prod'][0]['Id']] = {url:url,pdf:cartObj[item]['prod'][0]['photos'][0].name.includes('.pdf')}
              // [...prev,{url:url,pdf:cartObj[item].name.includes('.pdf')}]
            console.log(clone)
              return clone
            })
          })
        }


        setCartRef((prev)=>{
              const clone = JSON.parse(JSON.stringify(prev))

              clone['total'] += Number(((Number(cartObj[item]['prod'][cartObj[item]['sellerIndex']]['price']))*Number(cartObj[item]['qty'])).toFixed(2))
              clone['itemNum'] += Number(cartObj[item]['qty'])
            console.log(clone)
              return clone
            })

      })
    }
    console.log(userData)
  },[cartObj])



  // Format amount for diplay in the UI
  function formatAmount(amount, currency) {
    amount = zeroDecimalCurrency(amount, currency)
      ? amount
      : (amount / 100).toFixed(2);
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    }).format(amount);
  }

  // Check if we have a zero decimal currency
// https://stripe.com/docs/currencies#zero-decimal
function zeroDecimalCurrency(amount, currency) {
  let numberFormat = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  });
  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency = true;
  for (let part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false;
    }
  }
  return zeroDecimalCurrency;
}

  const placeOrder = async()=>{
    // console.log(user)
    if(user!=undefined&&user.uid!=undefined){

      if(isValid){
        const exp = expiry.split('/')
        const timestamp = +new Date()
        const payment = {shippingAddress:userData['lastUsedShippingAddress'],uid:user.uid,email:user.email,name:name,number:number,exp_month:exp[0],exp_year:exp[1],cvc:cvc,amount:cartRef['total'],timestamp:timestamp,cartObj:cartObj}
        // console.log(payment)
        
        setResponse('Placing Order...')
        setShowModal4(true)
        const order = await createStripePayment(payment)
        if(order.data!=undefined&&order.data.status == 'succeeded'){
          setResponse('Order Placed')
        }else{
          setResponse('An error occurred.  Order was not placed.')
        }
        console.log(order)
      }
    }else{
      setShowLogin(true)
    }
  }

  const deleteShippingAddress=()=>{

  }

  const cardCallback=(objj,isValid)=>{
    console.log(objj,isValid)
    if(objj.issuer!=undefined){
      setCardType(objj.issuer)
    }
    setIsValid(isValid)
  }

  const changeShippingAddress=async(obj)=>{
    if(userData['email']!=undefined&&userData['email']!=''){
      // const addresses = userData['addresses']!=undefined?userData['addresses']:[]
      try{
        setShowModal1(true)
        await setDoc(doc(db,'users',user.uid), {lastUsedShippingAddress:obj} , { merge: true })
        setShowModal1(false)
        setShowModal2(false)
      }
      catch(err){
        setShowModal1(false)
        alert(err)
      }
    }
  }
  
  const addNewShippingAddress=async()=>{
    console.log('addNewShippingAddress')
    if(userData['email']!=undefined&&userData['email']!=''){
      // const addresses = userData['addresses']!=undefined?userData['addresses']:[]
      try{
        setShowModal1(true)
        await setDoc(doc(db,'users',user.uid), {lastUsedShippingAddress:newAddressObj,shippingAddresses:arrayUnion(newAddressObj)} , { merge: true })
        setShowModal1(false)
        setShowModal3(false)
        setShowModal(false)
      }
      catch(err){
        setShowModal3(false)
        setShowModal1(false)
        alert(err)
      }
    }
  }

  const editNewShippingAddress=async()=>{
    console.log('addNewShippingAddress')
    if(userData['email']!=undefined&&userData['email']!=''){
      // const addresses = userData['addresses']!=undefined?userData['addresses']:[]
      try{
        setShowModal1(true)
        let indy=0;
        userData['shippingAddresses'].forEach((item,index)=>{
          if(item['address']==userData['lastUsedShippingAddress']['address']&&item['address2']==userData['lastUsedShippingAddress']['address2']){
            indy=index
          }
        })
        // console.log(indy)
        userData['shippingAddresses'][indy]=newAddressObj
        // console.log(userData['shippingAddresses'])
        await setDoc(doc(db,'users',user.uid), {lastUsedShippingAddress:newAddressObj,shippingAddresses:userData['shippingAddresses']} , { merge: true })
        setShowModal1(false)
        setShowModal3(false)
        setShowModal(false)
      }
      catch(err){
        setShowModal3(false)
        setShowModal1(false)
        alert(err)
      }
    }
  }
  

  const handleInputFocus = (e) => {
      setFocus(e.target.name);
    }
    
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
    

  //   this.setState({ [name]: value });
  // }

    const addNewCard=()=>{

    }






  return(
    <div className='root'>
    <Container style={{width:'100%'}}>
      <Row style={{width:'100%'}} onClick={()=>{}} >
      <Row>
        <Col xs='10'>
      <Row>
        <Col xs='12'>
        <h3 style={{paddingTop:10}}>
          Checkout
        </h3>
        </Col>
        
      </Row>
      <Row>
        <Col xs='12' style={{textAlign:'left'}}>
          <h5>
          Shipping Address
          </h5>
        </Col>
        <Col xs='10' style={{textAlign:'left',paddingLeft:30,paddingTop:10}}>
          {
            userData['lastUsedShippingAddress']!=undefined?
            <div>
              <div>{userData['lastUsedShippingAddress']['customerCompany']}</div>
              <div>{userData['lastUsedShippingAddress']['name']}</div>
              <div>{userData['lastUsedShippingAddress']['address']}</div>
              <div>{userData['lastUsedShippingAddress']['address2']}</div>
              <div>{userData['lastUsedShippingAddress']['city']}</div>
              <div>{userData['lastUsedShippingAddress']['state']}</div>
              <div>{userData['lastUsedShippingAddress']['zip']}</div>
              <div>{userData['lastUsedShippingAddress']['country']}</div>
            </div>
            :
            <div style={{color:'blue'}} onClick={()=>{setShowModal(true)}}>Add New</div>

          }
          
        </Col>
          {
            userData['lastUsedShippingAddress']!=undefined&&
            <Col xs='2' style={{color:'blue'}} onClick={()=>{setShowModal2(true)}}>
              Change
            </Col>
          }
          
        
      </Row>


      <div style={{borderTop:'1px solid black',paddingTop:10}} />


        <Row style={{paddingTop:20,paddingBottom:20,textAlign:'left'}}>
          <Col xs='6'>
            <h5>
              Billing Information
            </h5>

          </Col>

        </Row>
        
        <Row>
          <Col xs='12' lg='6' style={{padding:0}}>
            <form style={{}}>
              <input
                style={{padding:5}}
                type="tel"
                name="number"
                placeholder="Card Number"
                onChange={(e)=>{setNumber(e.target.value)}}
                onFocus={handleInputFocus}
              />
              <input
              style={{padding:5}}
                type="text"
                name="name"
                placeholder="Name"
                onChange={(e)=>{setName(e.target.value)}}
                onFocus={handleInputFocus}
              />
              <input
              style={{padding:5}}
                type="tel"
                name="expiry"
                placeholder="Valid Thru"
                onChange={(e)=>{setExpiry(e.target.value)}}
                onFocus={handleInputFocus}
              />
              <input
              style={{padding:5}}
                type="tel"
                name="cvc"
                placeholder="CVC"
                onChange={(e)=>{setCvc(e.target.value)}}
                onFocus={handleInputFocus}
              />              
            </form>
            </Col>
          <Col xs='12' lg='6' id='PaymentForm' style={{paddingTop:10}}>
            <Cards
              cvc={cvc}
              expiry={expiry}
              focused={focus}
              name={name}
              number={number}
              preview={true}
              callback={(e,f)=>{cardCallback(e,f)}}
            />
            </Col>
        
      </Row>
        <Row>
        <Col xs='12'>
          {
            cartObj!=undefined&&
            Object.keys(cartObj).map((item,index)=>{

              if(cartObj[item]['prod']==undefined){
                return
              }
              return(
                <Row key={cartObj[item]['prod'][0]['Id']+index} style={{padding:5,borderBottom:'1px solid black',borderTop:'1px solid black',}}>
                  <Col xs='12' lg='2' style={{padding:1}} >
                    {
                      imageRef[cartObj[item]['prod'][0]['Id']]!=undefined&&
                      <div>
                      {

                    !imageRef[cartObj[item]['prod'][0]['Id']]['pdf']?
                    <img style={{width:'100%'}} src={imageRef[cartObj[item]['prod'][0]['Id']]['url']}></img>
                    
                    :
                    
                    <object data={imageRef[cartObj[item]['prod'][0]['Id']]['url']} type="application/pdf">
                    </object>
                      }
                      </div>
                    }
                </Col>
                <Col xs='12' lg='8' style={{textAlign:'left'}}>
                  <Row>
                    <Col xs='12' lg='12' style={{}}>
                      {cartObj[item]['prod'][0]['Id']}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='12' lg='12' style={{}}>
                      {'Qty : '+cartObj[item]['qty']}
                    </Col>
                  </Row>
                </Col>
                <Col xs='12' lg='2' style={{textAlign:'right'}}>
                  {'$'+((Number(cartObj[item]['prod'][cartObj[item]['sellerIndex']]['price']))*Number(cartObj[item]['qty'])).toFixed(2)}
                </Col>

                </Row>
                )
            })
          }
        </Col>
      </Row>
      <Row>
        <Col xs='12' style={{textAlign:'right',borderTop:'1px solid black'}}>
         {'SubTotal ('+cartRef['itemNum']+' items): $'+cartRef['total']} 
        </Col>
      </Row>

      {/*need to make checkout Box*/}


        </Col>
        <Col xs='2' style={{paddingTop:25}}>
          <Row>
            <Col xs='12' style={{textAlign:'right'}}>
             {'SubTotal ('+cartRef['itemNum']+' items): $'+cartRef['total']} 
            </Col>
          </Row>
          <Row>
            <Col xs='12' style={{textAlign:'right',padding:10}}>
            {isValid&&
             <Button onClick={placeOrder}>Place Order</Button> 
            }
            </Col>
          </Row>
          
        </Col>

      </Row>
      </Row>

      {showModal&&
        <Modal
          show={true}
          onHide={()=>{setShowModal(false)}}
          backdrop="static"
          // keyboard={false}
        >
          <Modal.Header closeButton>
            
            <Modal.Title>New Shipping Address</Modal.Title>
              
          </Modal.Header>

          <Modal.Body>
            <Form8 userData={userData} newAddressObj={newAddressObj} setNewAddressObj={setNewAddressObj} addNewShippingAddress={addNewShippingAddress} />
          </Modal.Body>

        </Modal>
      }
      {showModal3&&
        <Modal
          show={true}
          onHide={()=>{setShowModal3(false)}}
          backdrop="static"
          // keyboard={false}
        >
          <Modal.Header closeButton>
            
            <Modal.Title>Edit Shipping Address</Modal.Title>
              
          </Modal.Header>

          <Modal.Body>
            <Form82 userData={userData} newAddressObj={newAddressObj} setNewAddressObj={setNewAddressObj} addNewShippingAddress={editNewShippingAddress} />
          </Modal.Body>

        </Modal>
      }

      {showModal1&&
        <Modal
          show={true}
          onHide={()=>{setShowModal1(false)}}
          backdrop="static"
          // keyboard={false}
        >
          <Modal.Header closeButton>
            
            <Modal.Title>Saving</Modal.Title>
              
          </Modal.Header>

          <Modal.Body>
            Saving...
          </Modal.Body>

        </Modal>
      }
      {showModal4&&
        <Modal
          show={true}
          onHide={()=>{setShowModal4(false)}}
          backdrop="static"
          // keyboard={false}
        >
          <Modal.Header closeButton>
            
            <Modal.Title>Placing Order</Modal.Title>
              
          </Modal.Header>

          <Modal.Body>
            {response}
          </Modal.Body>

        </Modal>
      }
      
      {showModal2&&
        <Modal
          show={true}
          onHide={()=>{setShowModal2(false)}}
          backdrop="static"
          // keyboard={false}
        >
          <Modal.Header closeButton>
            
            <Modal.Title>Change Shipping Address</Modal.Title>
              
          </Modal.Header>

          <Modal.Body>
          {

            <Form81 userData={userData} changeShippingAddress={changeShippingAddress} setShowModal={setShowModal} setShowModal3={setShowModal3} />
          }
          </Modal.Body>

        </Modal>
      }

    </Container>
        </div>

  );
}

export default Checkout;
