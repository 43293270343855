import { useState, useEffect } from 'react'
import { Container, Row, Col, Button } from "react-bootstrap";


const Form8 = ({setNewAddressObj,newAddressObj,addNewShippingAddress,setRed })=> {
  // useEffect(()=>{
  //   console.log(name)
  //   console.log(input)
  //   console.log(index)  
  // },[])
  // const [red, setRed] = useState(false)

  


  return (
    <div>
      <label style={{width:'100%',textAlign:'left'}}>
      Company
      <input
        style={{width:'100%'}}
        type="text" 
        value={newAddressObj['customerCompany']}
        onChange={(e) =>{

          setNewAddressObj((prevState) => ({
              ...prevState,
                ['customerCompany']: e.target.value,
              }
            ))
          }
        } 
      />
      </label><label style={{width:'100%',textAlign:'left'}}>
      Name
      <input
        style={{width:'100%'}}
        type="text" 
        value={newAddressObj['name']}
        onChange={(e) =>{

          setNewAddressObj((prevState) => ({
              ...prevState,
                ['name']: e.target.value,
              }
            ))
          }
        } 
      />
      </label>
      <label style={{width:'100%',textAlign:'left'}}>
      Address
      <input
        style={{width:'100%'}}
        type="text" 
        value={newAddressObj['address']}
        onChange={(e) =>{

          setNewAddressObj((prevState) => ({
              ...prevState,
                ['address']: e.target.value,
              }
            ))
          }
        } 
      />
      </label>
      <label style={{width:'100%',textAlign:'left'}}>
      Address 2
  <input
        style={{width:'100%'}}
        type="text" 
        value={newAddressObj['address2']}
        onChange={(e) =>{

          setNewAddressObj((prevState) => ({
              ...prevState,
                ['address2']: e.target.value,
              }
            ))
          }
        } 
      />
      </label>
      <label style={{width:'100%',textAlign:'left'}}>
      City
  <input
        style={{width:'100%'}}
        type="text" 
        value={newAddressObj['city']}
        onChange={(e) =>{

          setNewAddressObj((prevState) => ({
              ...prevState,
                ['city']: e.target.value,
              }
            ))
          }
        } 
      />
      </label>
      <label style={{width:'100%',textAlign:'left'}}>
      State
  <input
        style={{width:'100%'}}
        type="text" 
        value={newAddressObj['state']}
        onChange={(e) =>{

          setNewAddressObj((prevState) => ({
              ...prevState,
                ['state']: e.target.value,
              }
            ))
          }
        } 
      />
      </label>
      <label style={{width:'100%',textAlign:'left'}}>
      Zip
  <input
        style={{width:'100%'}}
        type="text" 
        value={newAddressObj['zip']}
        onChange={(e) =>{

          setNewAddressObj((prevState) => ({
              ...prevState,
                ['zip']: e.target.value,
              }
            ))
          }
        } 
      />
      </label>
      <label style={{width:'100%',textAlign:'left'}}>
      Country
    <input
      style={{width:'100%'}}
      type="text" 
      value={newAddressObj['country']}
      onChange={(e) =>{

        setNewAddressObj((prevState) => ({
            ...prevState,
              ['country']: e.target.value,
            }
          ))
        }
      } 
    />
      </label>
      <div style={{paddingTop:10,textAlign:'right'}}>
      
    <Button onClick={addNewShippingAddress} >Add</Button>
      </div>
    </div>
  );
}

export default Form8