import React, { useState, useEffect,useRef } from "react";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";

// import SuccessForm from "./SuccessForm.js";

import '../App.css';
import Form8 from './Form8.js';
import Form81 from './Form81.js';
import Form82 from './Form82.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDoc } from "firebase/firestore";
import { getFirestore, setDoc, collection, query, where, doc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from './firebase.js';
import LoginModal from './LoginModal.js'
import { FaCaretDown,FaCaretUp,FaSearch,FaRedo,FaBars } from 'react-icons/fa';
import {Modal, Spinner} from 'react-bootstrap';
import Dropdown from 'react-dropdown';
import { getStorage,ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate,useSearchParams } from "react-router-dom"
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

function Success({user,db,auth,setShowLogin,userData,cartObj,setCartObj}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const storage = getStorage(firebase);
  const navigate = useNavigate()
  const functions = getFunctions(firebase)
  const completeStripePayment = httpsCallable(functions, 'completeStripePayment');
  const [openDrop,setOpenDrop] = useState(false);

  const [search, setSearch] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showModal1, setShowModal1] = useState(false)
  const [showModal2, setShowModal2] = useState(false)
  const [showModal3, setShowModal3] = useState(false)
  const [showModal4, setShowModal4] = useState(false)
  const [response, setResponse] = useState('Placing Order...')
  const [showChangeCard, setShowChangeCard] = useState(false)
  const [imageRef,setImageRef] = useState({})
  const [cartRef,setCartRef] = useState({total:0,itemNum:0})
  const [newAddressObj,setNewAddressObj] = useState({'name':'','address':'','address2':'','city':'','state':'','zip':'','country':''})
  const [cvc,setCvc] = useState('')
  const [expiry,setExpiry] = useState('')
  const [focus,setFocus] = useState('')
  const [name,setName] = useState('')
  const [number,setNumber] = useState('')
  const [cardType,setCardType] = useState('')
  const [isValid,setIsValid] = useState(false)
  const [paymentMethods,setPaymentMethods] = useState({})
  const [paymentMethod,setPaymentMethod] = useState('')
  const [customer,setCustomer] = useState({})

  useEffect(()=>{
    if(user!=undefined&&user.uid!=undefined){
      // check()
      setShowLogin(false)
      placeOrder()
    }else{
      console.log('user',user)
      setShowLogin(true)
    }
  },[user]) 
  useEffect(()=>{
    if(userData!=undefined&&userData['cart']!=undefined){
      // reset cartObj
     setCartObj({})
    }
  },[userData])

  useEffect(()=>{
    // console.log(searchParams.get('session_id'))
    // console.log(searchParams.get('success'))
  },[searchParams])

// http://localhost:3001/success?success=true&session_id=cs_test_a11FvFPzCslMw2oFwzBbjWoSVZxlcbjrT5xI0FOEQ4K7RigVkNaqxrtFNh


  const placeOrder = async()=>{

    // get session id from navigation

      console.log(searchParams.get('session_id'))

      setResponse('Order Placed')
      // setShowModal4(true)
      const order = await completeStripePayment({session_id:searchParams.get('session_id')})
      console.log(order)
      if(order.data!=undefined&&order.data.session_status == 'succeeded'){
        setResponse('Order Placed')
      }else{
        setResponse('An error occurred.  Order was not placed.')
      }
      
  }




  return(
    <div className='root'>
    <Container style={{width:'100%'}}>
      <Row style={{width:'100%'}} onClick={()=>{}} >
        <Row>
          <Col xs='10'>
            <Row>
              <Col xs='12'>
                <h3 style={{paddingTop:10}}>
                  Payment Success
                </h3>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs='10'>
            <Row>
              <Col xs='12'>
                <div style={{paddingTop:10}}>
                  You will recieve an email confirmation and shipping updates.
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

      </Row>

      {showModal1&&
        <Modal
          show={true}
          onHide={()=>{setShowModal1(false)}}
          backdrop="static"
          // keyboard={false}
        >
          <Modal.Header closeButton>
            
            <Modal.Title>Saving</Modal.Title>
              
          </Modal.Header>

          <Modal.Body>
            Saving...
          </Modal.Body>

        </Modal>
      }
      {showModal4&&
        <Modal
          show={true}
          onHide={()=>{setShowModal4(false)}}
          backdrop="static"
          // keyboard={false}
        >
          <Modal.Header closeButton>
            
            <Modal.Title>Placing Order</Modal.Title>
              
          </Modal.Header>

          <Modal.Body>
            {response}
          </Modal.Body>

        </Modal>
      }

    </Container>
        </div>

  );
}

export default Success;
