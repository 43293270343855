import React, { useState, useEffect,useRef } from "react";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";

// import QuoteForm from "./QuoteForm.js";

import '../App.css';
import Form8 from './Form8.js';
import Form81 from './Form81.js';
import Form82 from './Form82.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDoc } from "firebase/firestore";
import { getFirestore, setDoc, collection, query, where, doc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from './firebase.js';
import LoginModal from './LoginModal.js'
import { FaCaretDown,FaCaretUp,FaSearch,FaRedo,FaBars } from 'react-icons/fa';
import {Modal, Spinner} from 'react-bootstrap';
import Dropdown from 'react-dropdown';
import { getStorage,ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate,useSearchParams } from "react-router-dom"
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

function Quote({user,db,auth,setShowLogin,userData,quoteObj,setQuoteObj,company}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const storage = getStorage(firebase);
  const navigate = useNavigate()
  const functions = getFunctions(firebase)
  const completeStripePayment = httpsCallable(functions, 'completeStripePayment');
  const [openDrop,setOpenDrop] = useState(false);

  const [search, setSearch] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showModal1, setShowModal1] = useState(false)
  const [showModal2, setShowModal2] = useState(false)
  const [showModal3, setShowModal3] = useState(false)
  const [showModal4, setShowModal4] = useState(false)
  const [response, setResponse] = useState('Submitting Quote Request')
  const [response1, setResponse1] = useState('Submitting Quote Request')
  const [showChangeCard, setShowChangeCard] = useState(false)
  const [imageRef,setImageRef] = useState({})
  const [cartRef,setCartRef] = useState({total:0,itemNum:0})
  // const [quoteObj,setQuoteObj] = useState({'name':'','address':'','address2':'','city':'','state':'','zip':'','country':''})
  const [cvc,setCvc] = useState('')
  const [expiry,setExpiry] = useState('')
  const [focus,setFocus] = useState('')
  const [name,setName] = useState('')
  const [number,setNumber] = useState('')
  const [cardType,setCardType] = useState('')
  const [isValid,setIsValid] = useState(false)
  const [paymentMethods,setPaymentMethods] = useState({})
  const [paymentMethod,setPaymentMethod] = useState('')
  const [customer,setCustomer] = useState({})
  
  // useEffect(()=>{
  //   if(user!=undefined&&user.uid!=undefined){
  //     // check()
  //     setShowLogin(false)
  //     placeOrder()
  //   }else{
  //     console.log('user',user)
  //     setShowLogin(true)
  //   }
  // },[user]) 

  // useEffect(()=>{
  //   if(userData!=undefined&&userData['cart']!=undefined){
  //     // reset cartObj
  //    setCartObj({})
  //   }
  // },[userData])

  useEffect(()=>{
    setQuoteObj((prev)=>({
      ...prev,
      ['Id']:String(+new Date()),
    }));  
  },[])

  const handleCheckChange=(status)=>{
    switch(status){
      case 'High Definition Plasma & Oxy-Fuel Cutting Services': setQuoteObj((prevState)=>({
                      ...prevState,
                      ['High Definition Plasma & Oxy-Fuel Cutting Services']:!prevState['High Definition Plasma & Oxy-Fuel Cutting Services'],
                      
                    })); 
                    
        break;
      case 'Screw Machining': setQuoteObj((prevState)=>({
                      ...prevState,
                      ['Screw Machining']:!prevState['Screw Machining'],
                      
                    })); 
                    
        break;
      case 'Waterjet Cutting Services': setQuoteObj((prevState)=>({
                      ...prevState,
                      ['Waterjet Cutting Services']:!prevState['Waterjet Cutting Services'],
                      
                    })); 
                    
        break;
      case 'Tool & Die Design & Repair Services': setQuoteObj((prevState)=>({
                      ...prevState,
                      ['Tool & Die Design & Repair Services']:!prevState['Tool & Die Design & Repair Services'],
                      
                    })); 
                    
        break;
      case 'Full Service Machine Shop': setQuoteObj((prevState)=>({
                      ...prevState,
                      ['Full Service Machine Shop']:!prevState['Full Service Machine Shop'],
                      
                    })); 
                    
        break;
      case 'Industrial Cable Sales & Repair Services': setQuoteObj((prevState)=>({
                      ...prevState,
                      ['Industrial Cable Sales & Repair Services']:!prevState['Industrial Cable Sales & Repair Services'],
                      
                    })); 
                    
        break;
      case 'Full Service Fabrication Shop': setQuoteObj((prevState)=>({
                      ...prevState,
                      ['Full Service Fabrication Shop']:!prevState['Full Service Fabrication Shop'],
                      
                    })); 
                    
        break;
      case 'Portable Line Boring': setQuoteObj((prevState)=>({
                      ...prevState,
                      ['Portable Line Boring']:!prevState['Portable Line Boring'],
                      
                    })); 
                    
        break;
      case 'Metal Stamping Services': setQuoteObj((prevState)=>({
                      ...prevState,
                      ['Metal Stamping Services']:!prevState['Metal Stamping Services'],
                      
                    })); 
                    
        break;
      case 'Steel Sales & Processing': setQuoteObj((prevState)=>({
                      ...prevState,
                      ['Steel Sales & Processing']:!prevState['Steel Sales & Processing'],
                      
                    })); 
                    
        break;
      case 'Other': setQuoteObj((prevState)=>({
                      ...prevState,
                      ['Other']:!prevState['Other'],
                      
                    })); 
                    
        break;
      
      
    }
  }
  

  const submit = async ()=>{
    const req = ['First Name','Last Name','Title','Company/Organization','Phone','Email','Address','Country','State','City','Zip']

    let all=true;

    for(let x=0;x<req.length-1;x++){
      if(quoteObj[req[x]]==undefined||quoteObj[req[x]]==''){
        all=false;
        break;
      }
    }

    if(all){

      setShowModal4(true)
     
        console.log(company,quoteObj)
      try{
      await setDoc(doc(db,'companies',company,'quotes',quoteObj['Id']), {quoteObj:quoteObj})
      setResponse1('Quote Request Submitted');
      setResponse('Your Quote Request has been submitted.  Someone will be in contact with you shortly. Thank you.');
      
      setTimeout(
        () => setShowModal4(false), 
        3000
      );
      
      }
      catch(err){
        setShowModal4(false)
        console.log(err)
        alert(err.message)
      }

    } else{
      alert('Fill in required fields')
    }

  }


  return(
    <div className='root'>
    <div className='main1'>
    <Container style={{width:'100%'}}>
      <Row style={{width:'100%'}} onClick={()=>{}} >
        
            <Row>
              <Col xs='12'>
                <h3 style={{paddingTop:10}}>
                  Quote
                </h3>
              </Col>
            </Row>
         
        <Row>
          <Col xs='12' lg='12'>
           
            <Row>
              <Col xs='12' lg='6' style={{paddingBottom: 10}}>
                <label style={{width:'100%',textAlign:'left'}}>
                  First Name *
                  <input
                    style={{width:'100%'}}
                    type="text" 
                    value={quoteObj['First Name']}
                    onChange={(e) =>{

                      setQuoteObj((prevState) => ({
                          ...prevState,
                            ['First Name']: e.target.value,
                          }
                        ))
                      }
                    } 
                  />
                  </label>
              </Col>
              <Col xs='12' lg='6' style={{paddingBottom: 10}}>
                <label style={{width:'100%',textAlign:'left'}}>
                  Last Name *
                  <input
                    style={{width:'100%'}}
                    type="text" 
                    value={quoteObj['Last Name']}
                    onChange={(e) =>{

                      setQuoteObj((prevState) => ({
                          ...prevState,
                            ['Last Name']: e.target.value,
                          }
                        ))
                      }
                    } 
                  />
                  </label>
              </Col>
            </Row>
            <Row>
              <Col xs='12' lg='6' style={{paddingBottom: 10}}>
                <label style={{width:'100%',textAlign:'left'}}>
                  Title *
                  <input
                    style={{width:'100%'}}
                    type="text" 
                    value={quoteObj['Title']}
                    onChange={(e) =>{

                      setQuoteObj((prevState) => ({
                          ...prevState,
                            ['Title']: e.target.value,
                          }
                        ))
                      }
                    } 
                  />
                  </label>
              </Col>
              <Col xs='12' lg='6' style={{paddingBottom: 10}}>
                <label style={{width:'100%',textAlign:'left'}}>
                  Company/Organization *
                  <input
                    style={{width:'100%'}}
                    type="text" 
                    value={quoteObj['Company/Organization']}
                    onChange={(e) =>{

                      setQuoteObj((prevState) => ({
                          ...prevState,
                            ['Company/Organization']: e.target.value,
                          }
                        ))
                      }
                    } 
                  />
                  </label>
              </Col>
            </Row>
            <Row>
              <Col xs='12' lg='6' style={{paddingBottom: 10}}>
                <label style={{width:'100%',textAlign:'left'}}>
                  Phone *
                  <input
                    style={{width:'100%'}}
                    type="text" 
                    value={quoteObj['Phone']}
                    onChange={(e) =>{

                      setQuoteObj((prevState) => ({
                          ...prevState,
                            ['Phone']: e.target.value,
                          }
                        ))
                      }
                    } 
                  />
                  </label>
              </Col>
              <Col xs='12' lg='6' style={{paddingBottom: 10}}>
                <label style={{width:'100%',textAlign:'left'}}>
                  Fax
                  <input
                    style={{width:'100%'}}
                    type="text" 
                    value={quoteObj['Fax']}
                    onChange={(e) =>{

                      setQuoteObj((prevState) => ({
                          ...prevState,
                            ['Fax']: e.target.value,
                          }
                        ))
                      }
                    } 
                  />
                  </label>
              </Col>
            </Row>
            <Row>
              <Col xs='12' lg='6' style={{paddingBottom: 10}}>
                <label style={{width:'100%',textAlign:'left'}}>
                  Email *
                  <input
                    style={{width:'100%'}}
                    type="text" 
                    value={quoteObj['Email']}
                    onChange={(e) =>{

                      setQuoteObj((prevState) => ({
                          ...prevState,
                            ['Email']: e.target.value,
                          }
                        ))
                      }
                    } 
                  />
                  </label>
              </Col>
              <Col xs='12' lg='6' style={{paddingBottom: 10}}>
                
              </Col>
            </Row>
            <Row>
              <Col xs='12' lg='6' style={{paddingBottom: 10}}>
                <label style={{width:'100%',textAlign:'left'}}>
                  Address 1 / P.O. Box *
                  <input
                    style={{width:'100%'}}
                    type="text" 
                    value={quoteObj['Address']}
                    onChange={(e) =>{

                      setQuoteObj((prevState) => ({
                          ...prevState,
                            ['Address']: e.target.value,
                          }
                        ))
                      }
                    } 
                  />
                  </label>
              </Col>
              <Col xs='12' lg='6' style={{paddingBottom: 10}}>
                <label style={{width:'100%',textAlign:'left'}}>
                  Address 2
                  <input
                    style={{width:'100%'}}
                    type="text" 
                    value={quoteObj['Address 2']}
                    onChange={(e) =>{

                      setQuoteObj((prevState) => ({
                          ...prevState,
                            ['Address 2']: e.target.value,
                          }
                        ))
                      }
                    } 
                  />
                  </label>
              </Col>
            </Row>
            <Row>
              <Col xs='12' lg='6' style={{paddingBottom: 10}}>
                <label style={{width:'100%',textAlign:'left'}}>
                  Country *
                  <input
                    style={{width:'100%'}}
                    type="text" 
                    value={quoteObj['Country']}
                    onChange={(e) =>{

                      setQuoteObj((prevState) => ({
                          ...prevState,
                            ['Country']: e.target.value,
                          }
                        ))
                      }
                    } 
                  />
                  </label>
              </Col>
              <Col xs='12' lg='6' style={{paddingBottom: 10}}>
                <label style={{width:'100%',textAlign:'left'}}>
                  State *
                  <input
                    style={{width:'100%'}}
                    type="text" 
                    value={quoteObj['State']}
                    onChange={(e) =>{

                      setQuoteObj((prevState) => ({
                          ...prevState,
                            ['State']: e.target.value,
                          }
                        ))
                      }
                    } 
                  />
                  </label>
              </Col>
            </Row>
            <Row>
              <Col xs='12' lg='6' style={{paddingBottom: 10}}>
                <label style={{width:'100%',textAlign:'left'}}>
                  City *
                  <input
                    style={{width:'100%'}}
                    type="text" 
                    value={quoteObj['City']}
                    onChange={(e) =>{

                      setQuoteObj((prevState) => ({
                          ...prevState,
                            ['City']: e.target.value,
                          }
                        ))
                      }
                    } 
                  />
                  </label>
              </Col>
              <Col xs='12' lg='6' style={{paddingBottom: 10}}>
                <label style={{width:'100%',textAlign:'left'}}>
                  Zip/Postal Code *
                  <input
                    style={{width:'100%'}}
                    type="text" 
                    value={quoteObj['Zip']}
                    onChange={(e) =>{

                      setQuoteObj((prevState) => ({
                          ...prevState,
                            ['Zip']: e.target.value,
                          }
                        ))
                      }
                    } 
                  />
                  </label>
              </Col>
            </Row>
            

            <Row>
              <Col xs='12' lg='12' style={{paddingBottom: 10}}>
                <h5 style={{textAlign:'left', paddingTop:10}}>
                Which applications are you interested in?
                </h5>
                <div style={{textAlign:'left'}}>
                <div>
                <label style={{textAlign:'left',paddingRight:10}}>
                <input
                  name="typeOfCustomer"
                  type="checkbox"
                  checked={quoteObj['High Definition Plasma & Oxy-Fuel Cutting Services']}
                  onChange={()=>{handleCheckChange("High Definition Plasma & Oxy-Fuel Cutting Services")}} />
                  <span> </span>
                  High Definition Plasma & Oxy-Fuel Cutting Services
                  </label>
                </div>
                <div>
                <label style={{textAlign:'left',paddingRight:10}}>
                <input
                  name="typeOfCustomer"
                  type="checkbox"
                  checked={quoteObj['Screw Machining']}
                  onChange={()=>{handleCheckChange("Screw Machining")}} />
                  <span> </span>
                  Screw Machining
                  </label>
                </div>
                <div>
                <label style={{textAlign:'left',paddingRight:10}}>
                <input
                  name="typeOfCustomer"
                  type="checkbox"
                  checked={quoteObj['Waterjet Cutting Services']}
                  onChange={()=>{handleCheckChange("Waterjet Cutting Services")}} />
                  <span> </span>
                  Waterjet Cutting Services
                  </label>
                </div>
                <div>
                <label style={{textAlign:'left',paddingRight:10}}>
                <input
                  name="typeOfCustomer"
                  type="checkbox"
                  checked={quoteObj['Tool & Die Design & Repair Services']}
                  onChange={()=>{handleCheckChange("Tool & Die Design & Repair Services")}} />
                  <span> </span>
                  Tool & Die Design & Repair Services
                  </label>
                </div>
                <div>
                <label style={{textAlign:'left',paddingRight:10}}>
                <input
                  name="typeOfCustomer"
                  type="checkbox"
                  checked={quoteObj['Full Service Machine Shop']}
                  onChange={()=>{handleCheckChange("Full Service Machine Shop")}} />
                  <span> </span>
                  Full Service Machine Shop
                  </label>
                </div>
                <div>
                <label style={{textAlign:'left',paddingRight:10}}>
                <input
                  name="typeOfCustomer"
                  type="checkbox"
                  checked={quoteObj[' Industrial Cable Sales & Repair Services']}
                  onChange={()=>{handleCheckChange(" Industrial Cable Sales & Repair Service")}} />
                  <span> </span>
                   Industrial Cable Sales & Repair Services
                  </label>
                </div>
                <div>
                <label style={{textAlign:'left',paddingRight:10}}>
                <input
                  name="typeOfCustomer"
                  type="checkbox"
                  checked={quoteObj['Full Service Fabrication Shop']}
                  onChange={()=>{handleCheckChange("Full Service Fabrication Shop")}} />
                  <span> </span>
                  Full Service Fabrication Shop
                  </label>
                </div>
                <div>
                <label style={{textAlign:'left',paddingRight:10}}>
                <input
                  name="typeOfCustomer"
                  type="checkbox"
                  checked={quoteObj['Portable Line Boring']}
                  onChange={()=>{handleCheckChange("Portable Line Boring")}} />
                  <span> </span>
                  Portable Line Boring
                  </label>
                </div>
                <div>
                <label style={{textAlign:'left',paddingRight:10}}>
                <input
                  name="typeOfCustomer"
                  type="checkbox"
                  checked={quoteObj[' Metal Stamping Services Service Machine Shop']}
                  onChange={()=>{handleCheckChange(" Metal Stamping Services Service Machine Shop")}} />
                  <span> </span>
                   Metal Stamping Services Service Machine Shop
                  </label>
                </div>
                <div>
                <label style={{textAlign:'left',paddingRight:10}}>
                <input
                  name="typeOfCustomer"
                  type="checkbox"
                  checked={quoteObj['Steel Sales & Processing']}
                  onChange={()=>{handleCheckChange("Steel Sales & Processing")}} />
                  <span> </span>
                  Steel Sales & Processing
                  </label>
                </div>
                <div>
                <label style={{textAlign:'left',paddingRight:10}}>
                <input
                  name="typeOfCustomer"
                  type="checkbox"
                  checked={quoteObj['Other']}
                  onChange={()=>{handleCheckChange("Other")}} />
                  <span> </span>
                  Other
                  </label>
                </div>
                  </div>

            <Row>
              <Col xs='12' lg='12' style={{paddingBottom: 10,paddingTop: 10}}>
                <label style={{width:'100%',textAlign:'left'}}>
                  Additional Details
                  <input
                    style={{width:'100%'}}
                    type="text" 
                    value={quoteObj['Additional Details']}
                    onChange={(e) =>{
                      setQuoteObj((prevState) => ({
                          ...prevState,
                            ['Additional Details']: e.target.value,
                          }
                        ))
                      }
                    } 
                  />
                  </label>
              </Col>
              </Row>
                
                  
                  
                




          </Col>
        </Row>

        <Row>
          <Col xs='12' lg='12' style={{paddingBottom: 10}}>
            <div>
            <Button onClick={submit}>Submit</Button>
            </div>
          </Col>
        </Row>

          </Col>
      </Row>
      </Row>

      {showModal1&&
        <Modal
          show={true}
          onHide={()=>{setShowModal1(false)}}
          backdrop="static"
          // keyboard={false}
        >
          <Modal.Header closeButton>
            
            <Modal.Title>Sumbiting</Modal.Title>
              
          </Modal.Header>

          <Modal.Body>
            Sumbiting...
          </Modal.Body>

        </Modal>
      }
      {showModal4&&
        <Modal
          show={true}
          onHide={()=>{setShowModal4(false)}}
          backdrop="static"
          // keyboard={false}
        >
          <Modal.Header closeButton>
            
            <Modal.Title>{response1}</Modal.Title>
              
          </Modal.Header>

          <Modal.Body>
            {response}
          </Modal.Body>

        </Modal>
      }

    </Container>
        </div>
        </div>

  );
}

export default Quote;


  

 
              