import React,{ useState, useEffect, useRef } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from "react-bootstrap";
// import { useParallax,Parallax,ParallaxBanner } from 'react-scroll-parallax';
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import './../App.css';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import Card from './Card.js'
import LeftArrow from './LeftArrow.js'
import RightArrow from './RightArrow.js'
import { BrowserRouter as Router, Route, Link, useNavigate,useLocation } from "react-router-dom";



import pic0 from "./../pictures/High Definition Plasma & Oxy-Fuel Cutting Services/Plasma-Oxy-Fuel-Cutting-System-big.jpg"
import pic1 from "./../pictures/Waterjet Cutting Services/Flow-Abrasive-Waterjet-Machine-big.jpg"
import pic2 from './../pictures/Full Service Machine Shop/Haas-Machining-Center-big.jpg';
import pic3 from './../pictures/Full Service Fabrication Shop/Press-Brake-big.jpg';
import pic4 from './../pictures/Metal Stamping Services/Metal-Stamping-Machine-big.jpg';
import pic5 from './../pic5.jpg';
import pic6 from './../pictures/Tool & Die Design & Repair Services/Tool-&-Die-Design-big.jpg';
import pic7 from './../pictures/Industrial Cable Sales & Repair Services/Industrial-Cables-big.jpg';
import pic8 from './../pictures/Portable Line Boring/Welder-big.jpg';
import pic9 from './../pictures/products/Stack-of-Channel-Steel-big.jpg';

const obj = [{id:'High Definition Plasma & Oxy-Fuel Cutting Services',pic:pic0,web:'high-definition-plasma-oxy-fuel-cutting'},{id:'Waterjet Cutting Services',pic:pic1,web:'waterjet-cutting-services'},{id:'Full Service Machine Shop',pic:pic2,web:'machining'},{id:'Full Service Fabrication Shop',pic:pic3,web:'metal-fabrication'},{id:'Metal Stamping Services',pic:pic4,web:'metal-stamping'},{id:'Screw Machining',pic:pic5,web:'screw-machining'},{id:'Tool & Die Design & Repair Services',pic:pic6,web:'tool-die-design-repair'},{id:'Industrial Cable Sales & Repair Services',pic:pic7,web:'industrial-cable-sales-repair'},{id:'Portable Line Boring',pic:pic8,web:'line-boring'},{id:'Steel: Sales and Processing',pic:pic9,web:'steel-sales'}]
const obj1 = {'High Definition Plasma & Oxy-Fuel Cutting Services':{words:['Lion Industries LLC has several cutting methods which provide our customers with the best system to suit their specific needs. We have a state-of-the-art high definition plasma/oxy fuel cutting system. We can cut sheets up to 12\' x 30\'.','High definition plasma is preferred to typical torch cutting because it is more accurate and has a much smaller heat affected zone. Steel can be accurately plasma cut in sheets ranging from 1/16" to 1 1/2". Our nesting program minimizes material usage thus reducing the cost of each part.','The shield gasses and amperages used for cutting vary according to the material being cut. Oxyfuel cutting is for material ranging from 1/2" to 14" thick and is controlled by the same computer controls as the high definition plasma.'],pic:pic0},
'Waterjet Cutting Services':{words:['Lion Industries LLC utilizes a Flow Abrasive Waterjet. A waterjet provides us with an unmatched versatility to cut materials beyond what is capable with other traditional systems such as plasma, laser or machining. Our system cuts material as thin as 1/32" and up to 8" thick. Most materials are cut with a fine abrasive (garnet) added to a high speed, high pressure stream of water. With just a push of a button, the machine can begin cutting with just the high pressure water. This is used in cutting material used for gaskets, foam, plastics, wood and rubber.','Our waterjet has a table large enough to cut a sheet up to 6\' x 12\'. The machine has a dynamic head which automatically compensates for changes in stream thickness (kerf) as it moves through the material being cut.','Waterjets can accurately cut any material except tempered glass. It is extremely useful for materials not normally cut by other processes. These materials include brass, copper, aluminum, stainless steel, glass, ceramics, and tool steel and stone. We can accurately cut to within .010". Nesting programs are used on multiple part runs to minimize material usage. Waterjet cutting cuts with a very narrow kerf. Thus the machine is capable of very fine details.'],pic:pic1},
'Full Service Machine Shop':{words:['Lion Industries LLC is a full service machine shop. Our shop includes vertical mills, horizontal mills, turret lathes, engine lathes, surface grinders, heat treating furnaces, hydraulic presses, band saws and CNC machines.','CNC Machining provides exceptional quality and speed of production. Lion Industries has both 3 and 4 axis vertical machining centers and a 3 axis turning center with live tooling. Our expert machinists produce highly accurate parts and when necessary, build specific fixtures for production of these parts.','These machines enable the production of parts which are:','\u2022 Highly accurate','\u2022 Have a low cost of production','\u2022 Enable rapid part production once programming and fixturing are complete'],pic:pic2},
'Full Service Fabrication Shop':{words:['Lion Industries LLC offers fabrication capabilities that are enhanced by the presence of such exceptional support from other departments. Within Lion Industries LLC, we have CNC press brakes with up to 500 ton capacity and 12\' bed. We have experience with a variety of materials, including tool steels, stainless steel alloys, brass & aluminum alloys.','Our full service fabrication service uses the combination of our machine shop, plasma/waterjet precision cutting equipment, bending/forming equipment & Stick/MIG/TIG welding to provide our customers with high quality components.','Lion Industries can manufacture, rebuild & repair all types of industrial components and assemblies.','\u2022 Battering rams','\u2022 Transportation department equipment: stainless steel salt spreader, truck beds','\u2022 Oil / Gas Industry: Catwalks, Platforms, Metering Skids & much more.','\u2022 Marine industry: boat docks','\u2022 Coal industry: Rail Cars, Material Chutes, Supply Tubs & all types of Steel Structures','\u2022 Scrap bins','\u2022 Spad drivers','\u2022 Clamshell buckets,Dozer Blades, Excavator and Backhoe Buckets','\u2022 Scrap hoppers','\u2022 Roll forming shafts','\u2022 Anchor bolts','\u2022 Rebuilds'],pic:pic3},
'Metal Stamping Services':{words:['Lion Industries LLC’s metal stamping department has the production capability to fabricate high volumes of metal stampings for our own product lines as well as contract stamping. We can produce metal stampings from customer supplied dies or custom built die sets made in our tool and die department.','Lion Industries provides metal stampings to a variety of industries, including coal mining, transportation & construction. With our high precision hydraulic presses ranging from 40 to 150 ton capacities, we can blank and form a variety of materials made from steel alloys, stainless steels & aluminum alloys.','With our extensive background in carbon steels, Lion Industries can mass produce metal stampings such as:','\u2022 Stabilization mats','\u2022 Spad nails','\u2022 Support T-section channel','\u2022 Packaging products'],pic:pic4},
'Screw Machining':{words:[],pic:pic5},
'Tool & Die Design & Repair Services':{words:['Lion Industries tool and die department supports both in-house and customer supplied die sets. Our conventional and CNC machine tools enable our tool and die makers to design, build and maintain dies for all of our metal stamping product lines.','Lion Industries routinely works with thin and heavy gauge materials made from steel alloys, stainless steels & aluminum alloys. With our extensive background in a variety of metal stamping and die set materials and features, the tool and die department provides customers with design assistance for finished die sets with or without the metal stamping production.','In addition, Lion Industries offers the convenience of repair and maintenance services for all die sets. During this process, we work closely with customers to determine any design improvements and modifications.'],pic:pic6},
'Industrial Cable Sales & Repair Services':{words:['Lion Industries has a full service cable repair and sales division located in Saint Clairsville, Ohio. We specialize in mining and industrial cables of all sizes and voltages. Our 110 years of combined cable experience enables us to correctly diagnose and repair your cable needs efficiently to reduce your company\'s down time, and thus improving your bottom line.','Some of our capabilities include transportation, installation, cable testing, trouble shooting, splicing, jacket repair, and coupler installation. We offer cable repair on your site or in our 7,500 square foot facility.','Industries Served:','\u2022 Mining/Coal','\u2022 Packaging','\u2022 Electrical','\u2022 Construction','\u2022 Transportation','\u2022 Marine','\u2022 Law Enforcement','\u2022 Steel'],pic:pic7},
'Portable Line Boring':{words:['Lion Industries LLC utilizes state-of-the-art line boring and welding equipment. Our spiral welder is programmable to skip areas where welding is not necessary such as at key ways. After the bore is welded it can be bored to the correct diameter. Our highly experienced crew is available to service your needs twenty four hours per day.'],pic:pic8},
'Steel: Sales and Processing':{words:['Lion Industries steel sales and processing capabilities enable us to fulfill all of your steel requirements. Lion Industries can process, through the use of our other services, and distribute a wide variety of metals including carbon steel, stainless steel and aluminum.','We offer competitive prices on many different metals in various shapes and sizes such as: rebar, deck and grates, wire and roll mesh, tubing, bars, pipes, plate, sheet, and structural steel.'],pic:pic9},
'About Lion Industries':{words:['Lion Industries\' founder spent 22 years operating underground coal mines so it was a logical decision when forming his new company that the end products be produced for the industry he knew best, coal mining. The company began operations in 1995. Since then this part of the company has continued to grow with new products and services to the industry. But, there has also been development of other non-coal products. Today, Lion Industries has a full CNC machine shop with both milling and turning capabilities which also includes die design, building and maintenance. Our fabrication shop includes cutting by waterjet, high definition plasma, and oxy-fuel machines. Lion Industries also specializes in custom made anchor bolts ranging from 1/2" to 4" in diameter.','Our production facilities include our main facility in Bellaire, Ohio with 90,000 square feet of shop floor and a smaller facility in Saint Clairsville, Ohio which includes 7,500 square feet. We are within 2 miles of Interstate 70 and close to both Interstates 77 and 79. Our capabilities continue to grow to serve the needs of our current and future customers.','Lion Industries LLC. is a proud supporter and purchaser of U.S. produced steel.']}
}

const getItems = () =>
  Array(10)
    .fill(0)
    .map((_, ind) => (obj[ind]));

const Main = ({setShowLogo,parallax,selected1,setSelected1}) => {

  const location = useLocation();
  const navigate = useNavigate();
  const [loc,setLoc] = useState('')

  useEffect(()=>{
  	console.log(location)
  	if(location!=null){
  console.log(location.pathname)
  		const splitLoc = location.pathname.split('/')
  		if(splitLoc[1]!=undefined){
  			// switchy(splitLoc[1])
  			for(let x=0;x<obj.length;x++){
  				if(obj[x]['web']==splitLoc[1]){
  					setSelected1(obj[x]['id'])
  				}
  			}
  			
  		}
  	}

  },[location])
  const switchy = (swic)=>{
  	switch(swic){
  				case 'high-definition-plasma-oxy-fuel-cutting': setSelected1('High Definition Plasma & Oxy-Fuel Cutting Services'); 
  					break;
  				case 'waterjet-cutting-services': setSelected1('Waterjet Cutting Services')
  					break;
  				case 'machining': setSelected1('Full Service Machine Shop')
  					break;
  				case 'metal-fabrication': setSelected1('Full Service Fabrication Shop')
  					break;
  				case 'metal-stamping': setSelected1('Metal Stamping Services')
  					break;
  				case 'screw-machining': setSelected1('Screw Machining')
  					break;
  				case 'tool-die-design-repair': setSelected1('Tool & Die Design & Repair Services')
  					break;
  				case 'industrial-cable-sales-repair': setSelected1('Industrial Cable Sales & Repair Services')
  					break;
  				case 'line-boring': setSelected1('Portable Line Boring')
  					break;
  				case 'steel-sales': setSelected1('Steel: Sales and Processing')
  					break;
					default: setSelected1('About Lion Industries')
  					
  			}
  }


	const [offset, setOffset] = useState(0);
	const [prevOffset, setPrevOffset] = useState(0);
	const refArray = useRef([])
	// const visRefArray = useRef([])


	const [items, setItems] = useState(getItems);
  const [selected, setSelected] = useState([]);
  const [position, setPosition] = useState(0);

  const isItemSelected = (id) => !!selected.find((el) => el === id);

  const handleClick = (id) => ({ getItemById, scrollToItem }) => {
      console.log(id)
      const itemSelected = isItemSelected(id);

      setSelected((currentSelected) =>
        itemSelected
          ? currentSelected.filter((el) => el !== id)
          : currentSelected.concat(id)
      );
      for(let x=0;x<obj.length;x++){

  				if(obj[x]['id']==id){
  					console.log('nav',obj[x]['web'])
  					navigate(obj[x]['web'])
  				}
  			}
    };

  const [screenWidth, setScreenWidth] = useState(768); 
  useEffect(()=>{
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", setScreenWidth(window.innerWidth));

    return () => {
      window.removeEventListener("resize", setScreenWidth(window.innerWidth));
    };
  },[])  

  useEffect(()=>{
  	console.log(selected1)

  },[selected1])


	const callHandler = (phoneNumber) => window.open(`tel:${phoneNumber}`, '_self');


	return(
		<div className="root">
			<div className="main1">
				<div className="container">

				{/*<AnimationOnScroll scrollableParentSelector='.root' animateIn="animate__slideInRight">
					<div className="img-wrapper">
			    	<img src={pic1} style={{width:400,paddingTop:5}} className='hover-zoom'></img>
			    </div>
				</AnimationOnScroll>*/}
				
				{/*<AnimationOnScroll scrollableParentSelector='.root' animateIn="animate__slideInLeft"  >
				</AnimationOnScroll>
				*/}

					<div style={{textAlign:'left',paddingLeft:10}}>
					<h2 style={{textAlign:'left'}}>Lion Industries</h2>
					<div style={{textAlign:'left',paddingBottom:'5px'}}>{"At Lion Industries, LLC we have metalworking capabilities which are more diverse than most companies. We are continually adding to our capabilities to meet our customers' needs. Lion Industries commitment to our customer's needs has led us to expand as our customers look for additional ways we can serve them. Our skilled workers can stamp, bend, roll, weld, and machine various metals in quantities from one to thousands."}</div>
					</div>

					<div className='horScroll'>
					<ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} style={{}}>
						    {/*<div style={{display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid black'}}>
*/}
					      {items.map(({ id,pic }) => (
					        <Card
					          itemId={id} // NOTE: itemId is required for track items
					          title={id}
					          pic={pic}
					          key={id}
					          onClick={handleClick(id)}
					          selected={isItemSelected(id)}
					          setSelected1={setSelected1}
					        />
					      ))}
					      {/*</div>*/}
					    </ScrollMenu>
					</div>

					<div style={{textAlign:'left',paddingTop:30}}>
					{
						selected1!=''?
						<div>
							<h5>
							{selected1}
							</h5>
							{
								obj1[selected1]['words'].map((item,index)=>{

									const tab = item.includes('\u2022')

									return(
										<div style={tab?{paddingTop:10,paddingLeft:15}:{paddingTop:10}}>
											{item}
										</div>
										)
								})
							}
						</div>
						:
						<div>
							<h5>
							About Lion Industries
							</h5>
							{
								obj1['About Lion Industries']['words'].map((item,index)=>{

									const tab = item.includes('\u2022')

									return(
										<div style={tab?{paddingTop:10,paddingLeft:15}:{paddingTop:10}}>
											{item}
										</div>
										)
								})
							}
						</div>
						

					}
					</div>


					{/*<div ref={refArray.current[0]}  style={{padding:60,transition:'transform 1s ease-in-out',border:'1px solid black',posistion:'absolute',left:-400,width:400,zIndex:1,backgroundColor:'black',opacity:.5}}>fuck</div>*/}
			    {/*<img ref={refArray.current[1]} src={pic1} style={{transition:'transform 1s ease-in-out',border:'1px solid black',display: 'inline-block',transform:'scale(.5)'}}></img>*/}
		    
		    
				</div>
	  	</div>

					<div style={{width:'100%',textAlign:'center'}} >
					<div style={{}} >
					Lion Industries LLC  |  423 53rd Street  |  Bellaire , OH 43906  |  Phone: 740-676-1100  |  Fax: 740-676-1101 |  E-mail:info@lionind.com  
					</div>
					</div>
				
	{/*		<div className="main1">
				<div className="container">
					<AnimationOnScroll scrollableParentSelector='.root' animateIn="animate__slideInRight">
				    <div className="img-wrapper">
			    	<img src={pic2} style={{width:400,paddingTop:5}} className='hover-zoom'></img>
			    </div>
					</AnimationOnScroll>

					<div style={{textAlign:'left',paddingLeft:10}}>
					<h2 style={{textAlign:'left'}}>Product Exellence</h2>
					
					<div style={{textAlign:'left',paddingBottom:'5px'}}>Fostoria Bushings caters to our customers’ special needs by providing a multitude of standard and customized bushings.</div>
					<div style={{textAlign:'left',paddingBottom:'5px'}}>We pride ourselves in coming up with significant additions to our range of related products like: 1) gaskets 2) nuts 3) washers 4) spades 5) custom porcelain for specific designs.</div>
					<div style={{textAlign:'left',paddingBottom:'5px'}}>Quality being one of our prime competitive advantages, we use 98 % conductivity copper in our castings whereas our competitors use 15 to 32% conductivity copper alloys.</div>
					<div style={{textAlign:'left',paddingBottom:'5px'}}>Our bushings meet or surpass existing industry ANSI, IEEE and NEMA standards.</div>
					<div style={{textAlign:'left',paddingBottom:'5px'}}>With constant innovation and advanced manufacturing technologies we have developed our unique sealing technique, while keeping our price extremely competitive in the industry.</div>
					<div style={{textAlign:'left',paddingBottom:'5px'}}>Fostoria Bushings’ standard spades are one piece and not brazed, which eliminates the possibility of creating hot spots, corrosion or contaminants reaching the transformer oil.</div>
					<div style={{textAlign:'left',paddingBottom:'5px'}}>In our consistent endeavor to maintain and improvise on the quality of our products, we select our raw material suppliers with utmost care.</div>
					<div style={{textAlign:'left',paddingBottom:'5px'}}>To meet any unforeseen circumstances and to ensure on-time delivery we maintain large inventories of porcelain and other key input components.</div>
		  	</div>

					
		  	</div>
	  	</div>

			<div className="main1">
				<div className="container">
					<AnimationOnScroll scrollableParentSelector='.root' animateIn="animate__slideInRight">
				    <div className="img-wrapper">
			    	<img src={pic4} style={{width:400,paddingTop:5}} className='hover-zoom'></img>
			    </div>
					</AnimationOnScroll>

			  
					
					<div style={{textAlign:'left',paddingLeft:10}}>
					<h2 style={{textAlign:'left'}}>Custom Products</h2>
					<div style={{textAlign:'left',paddingBottom:'5px'}}>One of the unique features of Fostoria Bushings is our highly experienced and competent engineering staff. With over 30 years of experience in the Porcelain Industry, we are capable of assisting you with special applications, designs and problem solving.</div>
					<div style={{textAlign:'left',paddingBottom:'5px'}}>Our stringent quality and confidentiality make custom products designed and created by Fostoria Bushings highly sought after.</div>
					<div style={{textAlign:'left',paddingBottom:'5px'}}>Our products are currently in use throughout the United States having been specified by some of the largest and most discerning OEM’s. Our bushings meet or surpass existing industry ANSI, IEEE and NEMA standards.</div>
					<div style={{textAlign:'left',paddingBottom:'5px'}}>All custom orders are considered confidential and are treated as such. Email correspondence about confidential, custom orders is done through SSL secured email.</div>
					<div style={{textAlign:'left',paddingBottom:'5px'}}>Delivery for orders with special assemblies, will be determined per quote.</div>
		  	</div>

				
				
		  	</div>
	  	</div>

			<div className="main1">
				<div className="container">
					<AnimationOnScroll scrollableParentSelector='.root' animateIn="animate__slideInRight">
				    <div className="img-wrapper">
			    	<img src={pic3} style={{width:400,paddingTop:5}} className='hover-zoom'></img>
			    </div>
					</AnimationOnScroll>

			    
						
					<div style={{textAlign:'left',paddingLeft:10}}>
						<h2 style={{textAlign:'left'}}>Contact Us</h2>
						<div style={{paddingTop:15}}>
		        	<Button style={{}} onClick={()=>{callHandler(4194357514)}}>(419) 435-7514</Button>
		        </div>
		        <div>
		        602 S Corporate Dr W suite d, Fostoria, OH 44830
		        </div>
		        <div>
		        Sales@FostoriaBushings.com
		        </div>
		        <div>
		        Open Mon-Fri 8am-5pm est
		        </div>
					</div>
		        
						<div style={{textAlign:'left'}}></div>

				</div>
			</div>*/}

		</div>
	)
}
export default Main