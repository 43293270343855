import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { FaAngleDoubleRight } from 'react-icons/fa';



function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      <FaAngleDoubleRight disabled={isLastItemVisible} onClick={() => scrollNext()}>
        Right
      </FaAngleDoubleRight>
    </div>
  );
}

export default RightArrow;
