import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { FaAngleDoubleLeft } from 'react-icons/fa';


function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
    <FaAngleDoubleLeft disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
      Left
    </FaAngleDoubleLeft>
    </div>
  );
}

export default LeftArrow;
