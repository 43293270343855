import '../App.css';
import { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDoc } from "firebase/firestore";
import { getFirestore, setDoc, collection, query, where, doc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from './firebase.js';
import { FaCaretDown,FaCaretUp,FaSearch,FaRedo,FaBars } from 'react-icons/fa';
import {Modal, Spinner} from 'react-bootstrap';
import Dropdown from 'react-dropdown';
import { getStorage,ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom"



function Cart({user,db,cartObj,setCartObj,userData,setShowLogin}) {
  
  const storage = getStorage(firebase);
  const navigate = useNavigate()
  const functions = getFunctions(firebase)
  const checkout1 = httpsCallable(functions, 'checkout');
  
  const [openDrop,setOpenDrop] = useState(false);
  const [imageRef,setImageRef] = useState({})
  const [cartRef,setCartRef] = useState({total:0,itemNum:0})
  const [numList, setNumList] = useState([]);
  const [num, setNum] = useState([]);


  useEffect(()=>{
    const array = []
    for(let x=0;x<=99;x++){
      array.push(String(x))
    }
    setNumList(array)
  },[])

  useEffect(()=>{
    console.log(cartObj)
    if(Object.keys(cartObj).length>=1){
          console.log('yup1')
      setCartRef({total:0,itemNum:0})
      setImageRef({})
      Object.keys(cartObj).forEach((item,index)=>{
        console.log(item)
        if(cartObj[item]['prod'][0]['photos']!=undefined&&cartObj[item]['prod'][0]['photos'].length>0){

          const downRef = ref(storage,'/photos/'+cartObj[item]['prod'][0]['photos'][0].storeId)
          getDownloadURL(downRef).then((url) => {
            console.log('yup2')
            // Insert url into an <img> tag to "download"
            setImageRef((prev)=>{
              const clone = JSON.parse(JSON.stringify(prev))

              clone[cartObj[item]['prod'][0]['Id']] = {url:url,pdf:cartObj[item]['prod'][0]['photos'][0].name.includes('.pdf')}
              // [...prev,{url:url,pdf:cartObj[item].name.includes('.pdf')}]
            console.log(clone)
              return clone
            })
          })
        }


        setCartRef((prev)=>{
              const clone = JSON.parse(JSON.stringify(prev))

              clone['total'] += Number(((Number(cartObj[item]['prod'][cartObj[item]['sellerIndex']]['price']))*Number(cartObj[item]['qty'])).toFixed(2))
              clone['itemNum'] += Number(cartObj[item]['qty'])
            console.log(clone)
              return clone
            })

      })
    }else{
      setCartRef({total:0,itemNum:0})
    }
  },[cartObj])

  const checkout = ()=>{
    navigate('/Checkout')

  }

  const checkout2 = async()=>{
    if(user!=null&&user.uid!=undefined&&user.uid!=null){

      if(Object.keys(cartObj).length>0){
        console.log(cartObj)
        try{

          const check = await checkout1({
            success_url:'http://localhost:3001/success',
            cancel_url:'http://localhost:3001/cart',
            cartObj:cartObj,
            customer_id:user.uid,
            customer_company:userData.currCompany,
            customer_rep:userData.name,
          })
          console.log(check)
          console.log(check.data)
          if(check.data.session_url!=undefined){
            window.location.replace(check.data.session_url);
          }else{
            alert('An error occurred. Please try again.')
          }
        }
        catch(error){
          console.log(error)
          alert(error.message)
        }
      }else{
        console.log('nothing in cart')
      }
    }else{
      setShowLogin(true)
    }

  }

  const deleteItem = (item)=>{
    console.log(item,'*******************************')
    setCartObj((prev)=>{
      const clone = JSON.parse(JSON.stringify(prev));
      delete clone[item]; 
      return clone
    })

  }


  return(
    <div className='root'>
    <div className='main1'>
    <Container style={{width:'100%'}}>
      <Row style={{width:'100%'}} onClick={()=>{}} >
      <Row>
        <Col xs='10'>
      <Row>
        <Col xs='6'>
        <h3 style={{paddingTop:10,textAlign:'left'}}>
          Shopping Cart
        </h3>
        </Col>
        <Col xs='6' style={{paddingTop:10,paddingBottom:0,display:'flex',flex:1,width:'100%',alignItems:'flex-end',justifyContent:'flex-end'}}>
          <div style={{flex:1,textAlign:'right' ,alignItems:'flex-end',justifyContent:'flex-end'}}>
            Price
          </div>
        
        </Col>
      </Row>
      <Row>
        <Col xs='12'>
          {
            cartObj!=undefined&&
            Object.keys(cartObj).map((item,index)=>{

              if(cartObj[item]['prod']==undefined){
                return
              }
              return(
                <Row key={cartObj[item]['prod'][0]['Id']+index} style={{padding:5,borderBottom:'1px solid black',borderTop:'1px solid black',}}>
                  <Col xs='12' lg='2' style={{padding:1}} >
                    {
                      imageRef[cartObj[item]['prod'][0]['Id']]!=undefined&&
                      <div>
                      {

                    !imageRef[cartObj[item]['prod'][0]['Id']]['pdf']?
                    <img style={{width:'100%'}} src={imageRef[cartObj[item]['prod'][0]['Id']]['url']}></img>
                    
                    :
                    
                    <object data={imageRef[cartObj[item]['prod'][0]['Id']]['url']} type="application/pdf">
                    </object>
                      }
                      </div>
                    }
                </Col>
                <Col xs='12' lg='8' style={{textAlign:'left'}}>
                  <Row>
                    <Col xs='12' lg='12' style={{}}>
                      {cartObj[item]['prod'][0]['Id']}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='12' lg='12' style={{}}>
                      <span>Qty : </span>
                      <Dropdown 
                        placeholderClassName="fuck"
                        arrowClosed={<FaCaretDown className="arrow-closed" />}
                        arrowOpen={<FaCaretUp className="arrow-open" />}
                        className="dropdown"
                        controlClassName="dropdownC"
                        menuClassName='menuClassName'
                        open={openDrop} options={numList} onChange={(e)=>{setCartObj((prev)=>{const clone = JSON.parse(JSON.stringify(prev));if(e.value=='0'){delete clone[item]}else{clone[item]['qty']=e.value}; return clone });console.log(e.value)}} value={cartObj[item]['qty']} placeholder={cartObj[item]['qty']}>
                      </Dropdown>
                    </Col>
                  </Row>
                  <Row>

                  {/*need to delete item from saved cart obj in firebase*/}

                    <Col xs='12' style={{fontSize:12,padding:5,paddingTop:15,paddingLeft:15,cursor:'pointer'}} onClick={()=>{deleteItem(item)}}>
                    Delete
                    </Col>
                  </Row>
                </Col>
                <Col xs='12' lg='2' style={{textAlign:'right'}}>
                  {'$'+((Number(cartObj[item]['prod'][cartObj[item]['sellerIndex']]['price']))*Number(cartObj[item]['qty'])).toFixed(2)}
                </Col>

                </Row>
                )
            })
          }
        </Col>
      </Row>
      <Row>
        <Col xs='12' style={{textAlign:'right'}}>
         {'SubTotal ('+cartRef['itemNum']+' items): $'+cartRef['total'].toFixed(2)} 
        </Col>
      </Row>

      {/*need to make checkout Box*/}


        </Col>
        <Col xs='2' style={{paddingTop:25}}>
          <Row>
            <Col xs='12' style={{textAlign:'right'}}>
             {'SubTotal ('+cartRef['itemNum']+' items): $'+cartRef['total'].toFixed(2)} 
            </Col>
          </Row>
          <Row>
            <Col xs='12' style={{textAlign:'right'}}>
             {/*<Button onClick={()=>{checkout()}}>Checkout</Button> */}
             <Button onClick={()=>{checkout2()}}>Checkout</Button> 
            </Col>
          </Row>
          
        </Col>

      </Row>
      </Row>

    </Container>
    </div>
    </div>
  );
}

export default Cart;
