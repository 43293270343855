import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import './../App.css';

function Card({ onClick, selected, title, itemId,pic }) {
  const visibility = React.useContext(VisibilityContext);



  return (
    // <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>

      <div
        onClick={() => {onClick(visibility);}}
        // style={{paddingLeft:2,paddingRight:2,}}
        tabIndex={0}
      >
        <div style={{padding:10}} className="card">
          <div style={{padding:10}}>{title}</div>
          <div className="img-wrapper">
            <img src={pic} style={{width:400,height:300,paddingTop:5,objectFit: 'cover'}} className='hover-zoom'></img>
          </div>
          {/*<div>visible: {JSON.stringify(!!visibility.isItemVisible(itemId))}</div>
          <div>selected: {JSON.stringify(!!selected)}</div>*/}
        </div>
        {/*<div
          style={{
            height: '200px',
          }}
        />*/}
      </div>
    // </div>
  );
}

export default Card;



