import '../App.css';
import { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button,Modal,Spinner } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage,ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFirestore,deleteDoc, setDoc, collection, query, where, doc, getDoc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";
import firebase from './firebase.js';
import { Outlet,useNavigate } from "react-router-dom"

// import Dropdown from 'react-dropdown';
import { FaCaretDown,FaCaretUp,FaSearch,FaRedo,FaBars } from 'react-icons/fa';



function Shop({user,db,setShowOutlet,showOutlet}) {
  
  const navigate = useNavigate()
  const storage = getStorage(firebase);

  const [percent,setPercent] = useState({});
  const [showLoading, setShowLoading] = useState(false);
  const [newNew, setNewNew] = useState(true);

  const [docData, setDocData] = useState({})
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFile1, setSelectedFile1] = useState([]);
  const [productObj, setProductObj] = useState([]);
  const [num, setNum] = useState(0);

  const queryRef = useRef();
  const dateQueryRef = useRef();
  const dragItem = useRef();
  const dragOverItem = useRef();
  const productRef = useRef({});
  const companiesList = useRef([]);

  const [list, setList] = useState(["Id","Description","Inventory"])
  const partList1 = ["Id","Description","Inventory"]; 
  const partList2 = ["Id","Description","Inventory","Base Cost","Labor","Overhead","Material","Total"]; 
  const [list1, setList1] = useState(['Id','Description','Qty.'])
  const compList1 = ['Id','Description','Qty.']
  const compList2 = ['Id','Description','Qty.',"Base Cost","Labor","Overhead","Material","Total"]
  const emptyCompObj = {'Id':'','Description':'','Qty.':'',"Base Cost":'',"Labor":'',"Overhead":'',"Material":'',"Total":''};
  const emptyPartObj = {"Id":"","Approved":false,"Description":"","Inventory":"","Base Cost":"","Labor":"","Overhead":"","Material":"","Total":"","components":[{'Id':'','Description':'','Qty.':'',"Base Cost":'',"Labor":'',"Overhead":'',"Material":'',"Total":''}],"componentIds":[],"images":[],"instructions":[]};
  const [imageRef,setImageRef] = useState([]);

  useEffect(()=>{
    // console.log(user)
    // getProducts()
    getProducts1()
  },[user])

  const getProducts1 = async ()=>{
    console.log('getting products')
    const q = doc(db,'products','prods');
    
    try{
      setDocData({})
      const docc = await getDoc(q);
      const data = docc.data()
      console.log(data)
      if(data.products!=undefined){
      console.log(data.products)
        const prods ={};
        Object.keys(data.products).forEach((item,index)=>{
          if(data.products[item][0]['company']=='Fostoria Bushings'){
            prods[item]=data.products[item]
          }
        })
        setDocData(prods)


      // Object.keys(data.products).forEach(async(item)=>{
      //   const q1 = doc(db,'companies',data.products[item]['company'],'parts',item);
      //   const doc1 = await getDoc(q1);
      //   const data1 = doc1.data()
      //   console.log(data1)
      //   // data1['company']=data.products[item]['company'];

      //   // setDocData(prev=>{
      //   //   const clone = JSON.parse(JSON.stringify(prev))
      //   //   clone[data1['Id']]=data1
      //   //   return clone
      //   // })

      // })
      }

    }
    catch(err){
      console.log(err)
    }
      
    
  }

  useEffect(()=>{
    // console.log(showOutlet, docData)

    // let pdf = false;
    //         if(docData[item][0]['images'].length>0){
    //           pdf = docData[item][0]['images'][0].name.includes('.pdf')
    //           const downRef = ref(storage,'/images/'+docData[item][0]['images'][0].storeId)
    //           getDownloadURL(downRef).then((url) => {
    //             // Insert url into an <img> tag to "download"
    //             imageRef.current[docData[item][0]['Id']] = url
    //             {/*console.log(imageRef.current)*/}
    //           })
    //         }



    setImageRef([])
      Object.keys(docData).forEach((item,index)=>{
        // console.log(item,docData[item])

    if(docData[item].length>=1&&docData[item][0]['photos']!=undefined&&docData[item][0]['photos'].length>0){
          // console.log('yup1')
        const downRef = ref(storage,'/photos/'+docData[item][0]['photos'][0].storeId)
        getDownloadURL(downRef).then((url) => {
          // console.log('yup2')
          // Insert url into an <img> tag to "download"
          setImageRef((prev)=>{
            const clone = JSON.parse(JSON.stringify(prev))
            clone[item] = {url:url,pdf:docData[item][0]['photos'][0].name.includes('.pdf')}
            // [...prev,{url:url,pdf:item.name.includes('.pdf')}]
            return clone
          })
          // console.log(imageRef)
        })
    }
      })

  },[docData])

  const getProducts = async ()=>{
    const q = doc(db,'companiesList','list');
    try{
      productRef.current ={}
      setNum(0)
      const docList = await getDoc(q);
      companiesList.current = docList.data()
      console.log(companiesList.current)

      companiesList.current.companies.forEach(async(item)=>{

      console.log(item,'fuck')
      const q1 = query(collection(db,'companies', item, "parts"), where("Total", "!=",''));
      
      const querySnapshot = await getDocs(q1);

        querySnapshot.forEach((doc) => {
          const data = doc.data()
      console.log(data)
            console.log(productRef.current)

          if(data['Id']!=undefined&&Number(data['Total'])>0){
            const newobj = {'Id':data['Id'],'company':item,'price':data['Total'],description:data['Description']}
            if(data['photos']!=undefined){
              newobj['photos'] = data['photos'];
            }else{
              newobj['photos'] = [];
            }
            if(productRef.current[newobj['Id']]==undefined){
              productRef.current[newobj['Id']] = [newobj]
            }else{
              productRef.current[newobj['Id']].push(newobj)
            }
          }
        });
        setNum(prevCount => prevCount + 1)
      })

    }
    catch(err){
      console.log(err)
    }
  }

  useEffect(()=>{

    if(num>0&&companiesList.current.companies!=undefined&&num==companiesList.current.companies.length){
      console.log(num,companiesList.current.companies.length)
      console.log(productRef.current)
      setDoc(doc(db,'products','prods'), {products:productRef.current} , { merge: false })

    }
  },[num])



  const uuidv4 = ()=> {
    return ([1e7]+1e3+4e3+8e3+1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }  


  return (
    <div className='root'>
    <div className='main1'>
    <Container >
    

    {
      !showOutlet&&
      <div style={{display: 'flex',flex:1,flexDirection:'row',flexWrap:'wrap',justifyContent:'center',alignItems:'center',padding:5}}  >
      
      {
        Object.keys(docData).length>=1&&
        Object.keys(docData).map((item,index)=>{
          {/*console.log(item, docData[item])*/}
            

          return(
            <div key={docData[item][0]['Id']+'indy'+index} style={{height:500,width:300,border:'1px solid black',borderRadius:25,margin:5}}>
              <div onClick={()=>{setProductObj(docData[item]);navigate('./'+docData[item][0]['Id'])}} style={{display:'flex',flex:1,flexDirection:'column',justifyContent:'center',alignItems:'center',height:'100%',width:'100%'}}>
                
                {
                  imageRef[docData[item][0]['Id']]!=undefined&&!imageRef[docData[item][0]['Id']]['pdf']?
                  <img style={{height:300,width:300,objectFit:'contain',padding:1}} src={imageRef[docData[item][0]['Id']]['url']}></img>
                  :
                  imageRef[docData[item][0]['Id']]!=undefined&&
                  <object data={imageRef[docData[item][0]['Id']]['url']} type="application/pdf" height='300px' width='300px'>
                  </object>
                }
                <div style={{}}>{docData[item][0]['Id']}</div>
                <div style={{}}>{docData[item][0]['description']}</div>
                <div style={{}}>{docData[item][0]['company']}</div>
                <div style={{}}>{'$'+(Number(docData[item][0]['price'])).toFixed(2)}</div>

              </div>
            </div>
          )
        })
      }     


    </div>
    }
    {
      showOutlet&&
      <Outlet context={[productObj,setProductObj]} />
    }  

  </Container>
  </div>
  </div>

  );
}

export default Shop;
