import '../App.css';
import { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button,Modal,Spinner } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage,ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFirestore,deleteDoc, setDoc, collection, query, where, doc, getDoc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from './firebase.js';
import { useParams,useOutletContext } from "react-router-dom"
import { FaCaretDown,FaCaretUp,FaSearch,FaRedo,FaBars } from 'react-icons/fa';
import Dropdown from 'react-dropdown';


function Product({user,db,setCartObj}) {
  
  const [productObj,setProductObj] = useOutletContext()
  const [productObj1,setProductObj1] = useState([])
  const params = useParams()
  const storage = getStorage(firebase);

  const [openDrop,setOpenDrop] = useState(false);
  const [docData, setDocData] = useState([])


  
  const [num, setNum] = useState(0);
  const [numList, setNumList] = useState([]);
  const [qty, setQty] = useState('1');
  const [sellerIndex, setSellerIndex] = useState(0);
  const [addedTimer, setAddedTimer] = useState(false);

  const timer = useRef([]);
  const [imageRef,setImageRef] = useState([]);

  
  useEffect(()=>{
    const array = []
    for(let x=1;x<=99;x++){
      array.push(String(x))
    }
    setNumList(array)
  },[])

  useEffect(()=>{
      console.log(params.productId,'fuck')
      imageRef.current = []
      console.log(productObj)
      if(productObj.length<=0){
              console.log('yup1111')

        getProduct()
      }else{

        setProductObj1(productObj)
       
      }

  },[productObj])

  useEffect(()=>{
          // console.log('yup')
          setImageRef([])
      if(productObj1.length>=1&&productObj1[0]['photos']!=undefined&&productObj1[0]['photos'].length>0){
            // console.log('yup1')
        productObj1[0]['photos'].forEach((item,index)=>{
          const downRef = ref(storage,'/photos/'+item.storeId)
          getDownloadURL(downRef).then((url) => {
            // console.log('yup2')
            // Insert url into an <img> tag to "download"
            setImageRef((prev)=>{
              const clone = JSON.parse(JSON.stringify(prev))
              clone[index] = {url:url,pdf:item.name.includes('.pdf')}
              // [...prev,{url:url,pdf:item.name.includes('.pdf')}]
              return clone
            })
            // console.log(imageRef)
          })
        })
      }
  },[productObj1])

  const getProduct = async ()=>{
    console.log('getting products')
    const q = doc(db,'products','prods');
    try{
      const docc = await getDoc(q);
      const data = docc.data()
      console.log(data)
      if(data.products!=undefined){
      setDocData(data.products)

      // data.products.forEach(async(item)=>{
      //   const q1 = doc(db,'companies',item['company'],'parts',item['Id']);
      //   const doc1 = await getDoc(q1);
      //   const data1 = doc1.data()
      //   console.log(data1)
      //   data1['company']=item['company'];

      //   setDocData(prev=>[...prev,data1])

      // })
      }

    }
    catch(err){
      console.log(err)
    }    
  }

  useEffect(()=>{
    console.log(Object.keys(docData).length,docData)
    if(Object.keys(docData).length>0&&productObj.length<=0&&docData[params.productId]!=undefined){
    console.log('In there like swimware')
      setProductObj1(docData[params.productId])
    }
  },[docData])



  const uuidv4 = ()=> {
    return ([1e7]+1e3+4e3+8e3+1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }


  const addToCart = async()=>{
    try{
    setCartObj((prev)=>{
      const clone = JSON.parse(JSON.stringify(prev))
      // const objjjj={}
      if(clone[productObj1[sellerIndex]['Id']+'-Index-'+sellerIndex]!=undefined){
         clone[productObj1[sellerIndex]['Id']+'-Index-'+sellerIndex]['qty']=String(Number(clone[productObj1[sellerIndex]['Id']+'-Index-'+sellerIndex]['qty'])+Number(qty))
      }else{
        clone[productObj1[sellerIndex]['Id']+'-Index-'+sellerIndex]={prod:productObj1,qty:qty,sellerIndex:sellerIndex}
      }
       // clone.push(objjjj)
      return clone;
      })
      setAddedTimer(true)
    }
    catch(err){
    }
    }
    useEffect(()=>{
      if(addedTimer){
        console.log('timer')
      timer.current=setTimeout(()=>{setAddedTimer(false);console.log('timer1');},1000); 
      }
      return ()=>{clearTimeout(timer.current)}
    },[addedTimer])
    

  return (
    <div className='root'>

    <Container style={{width:'100%'}} >
    
    <Row style={{width:'100%'}}>
      <Col xs='1' lg='1'>
      {
        imageRef.length>=1&&
        
        imageRef.map((item,index)=>{
          {/*console.log(item)*/}
          if(item==undefined){
            return
          }
          return(
            <Row key={item+index}>
              <Col xs='12' lg='12' style={num==index?{padding:1,border:'1px solid gold'}:{padding:1}} onClick={()=>setNum(index)}>
                {

                !item['pdf']?
                <img style={{width:'100%'}} src={item['url']}></img>
                
                :
                
                <object data={item['url']} type="application/pdf">
                </object>
                }
            </Col>
            </Row>
            )
        })
        
      }
          </Col>
        <Col xs='4' lg='4'>
      {
       imageRef.length>=1&&imageRef[num]!=undefined&&
        <div>
          {

          !imageRef[num]['pdf']?
          <img style={{width:'100%'}} src={imageRef[num]['url']}></img>
          
          :
          
          <object data={imageRef[num]['url']} type="application/pdf">
          </object>
          }
        </div>
      }
      </Col>


      {
        productObj1!=undefined&&productObj1[0]!=undefined&&productObj1[0]['Id']!=undefined&&
        <Col  xs='5' lg='5'>
          <div style={{flex:1,width:'100%'}}>
            <div>{productObj1[0]['Id']}</div>
            <div>{productObj1[0]['description']}</div>
            <div>{productObj1[sellerIndex]['company']}</div>
            <div>{'$'+(Number(productObj1[sellerIndex]['price'])).toFixed(2)}</div>
          </div>
        </Col>
      }

      <Col xs='2' lg='2'>

        <Row>
          <Col xs='6' style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center'}}>
            <div style={{flex:1}}>
                Qty.
            </div>
          </Col>
          <Col xs='6'>
          <Dropdown 
            placeholderClassName="fuck"
            arrowClosed={<FaCaretDown className="arrow-closed" />}
            arrowOpen={<FaCaretUp className="arrow-open" />}
            className="dropdown"
            controlClassName="dropdownC"
            menuClassName='menuClassName'
            open={openDrop} options={numList} onChange={(e)=>{setQty(e.value);console.log(e.value)}} value={qty} placeholder={qty}>
          </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col xs='12' style={{padding:5}}>
            <Button onClick={addToCart}>Add To Cart</Button>
          </Col>
        </Row>
        {
          addedTimer&&
        <Row>
          <Col xs='12' style={{padding:5,color:'red'}}>
            <div>added to cart</div>
          </Col>
        </Row>
        }
        
      </Col>

    </Row>
  
  </Container>
  </div>

  );
}

export default Product;
