import { useState, useEffect, useRef } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar } from "react-bootstrap";
import logo from '../logo.png'
import { BrowserRouter as Router, Route, Link, useNavigate,useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";

const Header = ({expanded, setExpanded, header, parallax,setShowOutlet,setShowLogin,auth,user,cartObj,setSelected1}) => {

  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [qty, setQty] = useState(0);
     
  const close = () =>{
    // console.log("close ")
    setExpanded(false)
  } 

  const scroll = (to) => {
    // console.log("click ", to, " : fuck")
    setExpanded(false)
    if(to=='logout'){
      signOut(auth)
    }else if(to=='login'){
      // console.log("login",to)
      setShowLogin(true)
    }else{
      if(to==''){
        setSelected1(to)
      }
      navigate('./'+to)

    }
  }

  useEffect(()=>{
    console.log(location.pathname)
    const split = location.pathname.split('/')
    // console.log(split)
    if(split.length>=3){
      setShowOutlet(true)
    }else{
      setShowOutlet(false)
    }

  },[location])

  useEffect(()=>{
    if(cartObj!=undefined){
      setQty(0)
      Object.keys(cartObj).forEach((item)=>{
        console.log(cartObj[item])
        setQty((prev)=>{
          let clone = JSON.parse(JSON.stringify(prev))
          clone += Number(cartObj[item]['qty'])
          return clone
        })
      })
    }
  },[cartObj])

  return (
    <div ref={header} style={{zIndex:'999',backgroundColor:'white'}} >
    <Navbar expanded={expanded} className='navbar' collapseOnSelect expand="lg" variant="light">
      <Nav.Link onClick={()=>{scroll('')}} style={{ textDecoration: 'none',display:'flex',alignItems: 'center', justifyContent: 'center'}} to="/">
        <img
          // style rounded corners
          style={{marginLeft:20,marginRight:20}}
          src={logo}
          alt="Logo"
          width="50"
          height="50"
          className="d-inline-block align-top"
        />
        <span className="link"> Lion Industries</span>
      </Nav.Link>
      <Navbar.Toggle onClick={() => setExpanded(expanded ? false : true)} aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse onClick={close} id="responsive-navbar-nav">
        <Nav className="mr-auto">
          {/*<Nav.Link onClick={()=>{scroll('shop')}}><span className="link">Shop</span></Nav.Link>*/}
          {/*<Nav.Link onClick={()=>{scroll('cart')}}><span className="link">Cart</span><span className="cartQty">{qty}</span></Nav.Link>*/}
          <Nav.Link onClick={()=>{scroll('quote')}}><span className="link">Quote</span></Nav.Link>
          {/*<Nav.Link onClick={()=>{scroll('contact')}}><span className="link">Contact</span></Nav.Link>*/}
          {user!=undefined?
          <Nav.Link onClick={()=>{scroll('logout')}}><span className="link">Logout</span></Nav.Link>
          :
          <Nav.Link onClick={()=>{scroll('login')}}><span className="link">Login</span></Nav.Link>
          }
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    </div>

  );
}



export default Header